/*! 2025-4-2 20:17:05 */
[data-v-5eeaa1d2]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.log-wrapper[data-v-5eeaa1d2]{padding:5px 15px 15px 15px}.log-wrapper .list-item[data-v-5eeaa1d2]{margin-bottom:10px;line-height:26px;padding-left:5px}

[data-v-fdbea00e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.operationContent[data-v-fdbea00e]{width:100%;overflow:hidden}.operationContent span[data-v-fdbea00e]{width:calc(100% - 20px);display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.text-ellipsis[data-v-fdbea00e]{-webkit-line-clamp:3;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}[data-v-fdbea00e] .el-table__header,[data-v-fdbea00e] .el-table__body{border-left:1px solid #ebeef5}


.el-autocomplete[data-v-5d089543] {
  width: 100%;
}
.remote-select[data-v-5d089543] {
  width: 100%;
}
.remote-select[data-v-5d089543] .el-select-dropdown__list {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.remote-select[data-v-5d089543] .el-select-dropdown__list li {
  max-width: 470px;
}
/* 去除textarea的滚动条 */
.delete-scrollbar[data-v-5d089543] .el-textarea__inner {
  overflow: hidden;
  padding: 5px 10px;
}

[data-v-e811a418]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.wewoo-remote-select[data-v-e811a418] .el-autocomplete-suggestion{width:auto !important;min-width:100px;text-align:left}.ticket-table[data-v-e811a418] .el-table .cell{padding:0 2px}.ticket-table[data-v-e811a418] .el-table .cell .el-form-item{margin:14px 0}.ticket-table[data-v-e811a418] .el-table .cell .el-form-item .el-select{width:100% !important}.ticket-table[data-v-e811a418] .el-table .cell .el-form-item .el-date-editor{width:100% !important}.ticket-table[data-v-e811a418] .el-table .cell .el-form-item .el-date-editor.el-input .el-input__inner{padding:0 4px 0 26px}.ticket-table[data-v-e811a418] .el-table .cell .el-form-item .el-input .el-input__inner{text-align:center;padding:0 4px}.ticket-table .add-row[data-v-e811a418]{text-align:center;padding:10px 0 30px}.ticket-table .add-row span[data-v-e811a418]{cursor:pointer;color:#409eff}

[data-v-65979df0]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.ticket-table[data-v-65979df0] .el-table .cell{padding:0 2px}.ticket-table[data-v-65979df0] .el-table .cell .el-form-item{margin:14px 0}.ticket-table[data-v-65979df0] .el-table .cell .el-form-item .el-input .el-input__inner{text-align:center;padding:0 4px}.ticket-table[data-v-65979df0] .el-table .cell .el-form-item .colorRed{color:red}.ticket-table[data-v-65979df0] .el-table .cell .el-form-item .colorRed .el-input__inner{color:red}.ticket-table .add-row[data-v-65979df0]{text-align:center;padding:10px 0 30px}.ticket-table .add-row span[data-v-65979df0]{cursor:pointer;color:#409eff}

.operate-dialog{width:auto !important;position:absolute !important;left:10% !important;right:10% !important;top:10% !important;bottom:10%;margin:0 !important;display:flex !important;flex-direction:column !important}.operate-dialog .el-dialog__body{padding:20px 30px !important;flex:1 !important;overflow:auto !important}.operate-dialog .el-dialog__footer .dialog-footer{display:flex !important;justify-content:center !important}

[data-v-1b910a01]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.query-dialog .el-dialog__body[data-v-1b910a01]{padding:20px 30px !important}.query-dialog .el-dialog__footer .dialog-footer[data-v-1b910a01]{padding:10px 100px !important;display:flex !important;justify-content:center !important}

.wewoo-remote-select .el-autocomplete-suggestion{width:auto !important;min-width:100px;text-align:left}.add-new-note{position:absolute !important;left:50% !important;top:0 !important;bottom:0 !important;margin-left:-550px !important;margin-bottom:0 !important;display:flex !important;flex-direction:column !important}.add-new-note .el-dialog__header{padding:10px 0 20px 10px !important;display:flex !important;justify-content:space-between !important;align-items:center !important}.add-new-note .el-dialog__body{flex:1 !important;overflow-y:auto !important;padding:0 20px !important}.add-new-note .el-dialog__body .query-form-heard{padding:0 !important}.add-new-note .el-dialog__body .query-form-heard .el-col{padding:0 30px !important}.add-new-note .el-dialog__body .query-form-heard .el-col.search-btn{text-align:right !important;margin-bottom:20px !important}.add-new-note .pl11 .el-form-item__label{padding-left:11px !important}.add-new-note .total-amount{font-size:16px;color:#000;margin-bottom:20px}

[data-v-8c996da8]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.ticket-management-edit[data-v-8c996da8]{height:100%;overflow:hidden;overflow-y:auto}.ticket-management-edit .hearder-group[data-v-8c996da8]{display:flex;justify-content:space-between}.ticket-management-edit .hearder-group .hearder-group-tit[data-v-8c996da8]{display:flex;align-items:center;margin-bottom:20px}.ticket-management-edit .hearder-group .hearder-group-tit .el-page-header[data-v-8c996da8]{margin:0}.ticket-management-edit .ticket-management-edit-cont[data-v-8c996da8]{padding:0 20px}.ticket-management-edit .file-box[data-v-8c996da8]{padding:20px}.ticket-management-edit .write-off-results p[data-v-8c996da8]{padding:20px 0 20px 0;font-size:18px;color:#333}


/*# sourceMappingURL=chunk-45b0c0c0.8d4d7ffa.css.map*/